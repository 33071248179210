// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import authGroup from "./module/authGroup"
import authMenu from "./module/authMenu"
import authUser from "./module/authUser"
import basicFloor from "./module/basicFloor"

import basicRoom from "./module/basicRoom"
import basicPnrClass from "./module/basicPnrClass"
import nfc from "./module/nfc"
import nfcWorker from "./module/nfcWorker"
import camera from "./module/camera"
import loginAuth from "./module/loginAuth"

// counter, cart : Redux Test
const rootReducer = { 
	navbar, 
	layout, 
	authGroup, 
	authMenu, 
	authUser,
	basicFloor,
	basicRoom,
	basicPnrClass,
	nfc,
	nfcWorker,
	camera,
	loginAuth
}

export default rootReducer
