import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug:false,
		fallbackLng:'ko',
		interpolation:{
			escapeValue:false
		},
        supportedLngs:['ko', 'en'],
		resources:{
            ko:{
                translation:{
                    common:{
                        camera:'카메라',
                        use:'사용',
                        unuse:'미사용',
                        confirm:'확인',
                        change:'변경',
                        list:'목록'
                    },
                    dashboard:{
                        bread:{
                            title: '알림 관리'
                        },
                        summary_card:{
                            total:'총 이벤트',
                            fire: '화재(불꽃/연기/아크)',
                            spark : '화재(스파크)',
                            pollution: '오염',
                            unsafe_action: '불안전 행동',
                            unsafe_condition: '불안전 상태'
                        },
                        table:{
                            date: '일시',
                            camera: '카메라',
                            detect_class:'탐지 항목',
                            detect_detail :'탐지 항목 상세',
                            detail_button :'상세'
                        }
                    },
                    camera:{
                        bread:{
                            title: '카메라 그룹 관리',
                            parent:'기본정보',
                            manage_title: '카메라 조회',
                            are_title:'카메라 영역 관리'
                        },
                        group:{
                            group_name: '카메라 그룸 이름',
                            view_order:'보기순서',
                            description:'비고',
                            manage:'관리',
                            modify: '수정',
                            delete : '삭제',
                            register :'등록',
                            search: '검색',
                            search_placeholder: '카메라 그룹 이름을 검색해 보세요.',
                            form_title:'카메라 그룹',
                            form_sub_title: '빈칸에 맞춰 양식을 작성해 주세요.',
                            form_name :'그룹명',
                            check:'중복검사',
                            form_placeholder: '보기순서를 입력하지 않을시 자동으로 부여됩니다.',
                            cancle: '취소'
                        },
                        manage:{
                            column_name: '카메라 이름',
                            column_group: '카메라 그룹',
                            column_use: '사용여부',
                            column_state: '연결확인',
                            column_location: '위치',
                            column_manufacturer: '제조사',
                            search_placeholder: '카메랑 이름 또는 지역을 입력해 주세요.',
                            form_name:'카메라 이름',
                            group_error:'카메라 그룹을 선택해주세요.',
                            detect_placeholder: '탐지 항목을 선택해주세요.',
                            form_mainrtsp:'메인 스트림',
                            form_subrtsp:'서브 스트림',
                            class_list:'탐지 항목'
                        },
                        area:{
                            area_state:'상태',
                            form_title: '카메라 영역 설정',
                            form_list: '영역 목록',
                            form_area:'영역',
                            form_person_num:'인원수',
                            form_start:'시작',
                            form_end :'종료',
                            form_color:'색상'
                        }
                    },
                    employee:{
                        bread:{
                            title:'직원정보',
                            parent2:'직원정보관리',
                            auth_title:'사용자권한관리'
                        },
                        table:{
                            name:'이름',
                            username:'아이디',
                            admin:'권한',
                            status:'상태',
                            phone:'핸드폰',
                            no_img:'이미지 없음',
                            email:'이메일',
                            manager_number:'관리 일련번호',
                            start_date:'입사일',
                            end_date:'퇴사일',
                            create_datetime:'등록일',
                            update_datetime:'수정일',
                            password:'패스워드',
                            manager:'관리자',
                            worker:'작업자',
                            state_0:'신청',
                            state_1:'재직',
                            state_2:'퇴직',
                            description:'비고(주요자격)',
                            file:'첨부파일',
                            file_sub:'(직원사진)',
                            admin_setting:'권한 설정'
                        },
                        button:{
                            register:'직원 등록',
                            fix:'재설정'
                        },
                        common:{
                            auth:'사용자권한관리',
                            employee:'직원',
                            search_placeholder:'직원이름을 검색해 보세요.',
                            form_placeholder:'이름을 입력해주세요.',
                            phone_placeholder:'- 없이 작성해주세요'
                        }
                    },
                    access:{
                        title : '사용자 활동기록',
                        parent:'접속관리',
                        placeholder: '접속자 이름, ID 또는 로그 정보를 입력하세요.',
                        table_user_id: '접속 ID',
                        table_user_name: '접속자',
                        table_date: '최근접속일시',
                        table_info:'로그정보',
                        table_log_ip:'접속 IP',
                        table_target_user:'정보주체'
                    },
                    settings: {
                        manage: {
                            title: '서버 설정',
                            parent: '시스템 설정',
                            parent2: '서버 설정',
                            parent3: '수정',
                            sessionTime: '세션 로그아웃 시간'
                        }
                    }
                }
            },
            en: {
                translation:{
                    common:{
                    },
                    dashboard:{
                        summary_card:{
                            total:'Total Events',
                            fire: 'Fire1',
                            spark : 'Fire2',
                            pollution: 'Pollution',
                            unsafe_action: 'Unsafe Action',
                            unsafe_condition: 'Unsafe Condition'
                        }
                    }
                }
            }
        }
    })

export default i18n